import Cookies from "js-cookie";

type ClassWalletSessionData = {
  readonly orderId: string;
  readonly email: string;
};

function isClassWalletSessionData(
  data: unknown,
): data is ClassWalletSessionData {
  return (
    typeof data === "object" &&
    !!data &&
    "orderId" in data &&
    typeof data.orderId === "string" &&
    "email" in data &&
    typeof data.email === "string"
  );
}

const cookieName = "classWalletSession";

function setClassWalletSession(data: ClassWalletSessionData) {
  if (typeof window === "undefined") {
    return;
  }

  Cookies.set(cookieName, JSON.stringify(data), {
    path: "/",
  });
}

function getClassWalletSession() {
  if (typeof window === "undefined") {
    return undefined;
  }

  const rawValue = Cookies.get(cookieName);
  if (rawValue === undefined) {
    return undefined;
  }

  try {
    const value = JSON.parse(rawValue);
    if (!isClassWalletSessionData(value)) {
      Cookies.remove(cookieName);
      return undefined;
    }
    return value;
  } catch {
    return undefined;
  }
}

function deleteClassWalletSession() {
  if (typeof window === "undefined") {
    return;
  }

  Cookies.remove(cookieName);
}

export type { ClassWalletSessionData };
export {
  deleteClassWalletSession,
  getClassWalletSession,
  setClassWalletSession,
};
