import React, { useEffect } from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import Seo from "~/components/Seo";
import TitleBanner from "~/components/TitleBanner";
import TwoColumnTextImage from "~/components/TwoColumnTextImage";
import StandardTestimonials from "~/components/StandardTestimonials";
import CostBenefits from "~/features/marketing/CostBenefits";
import WhatDoYouGet from "~/features/marketing/WhatDoYouGet";
import LegUpSection from "~/components/LegUpSection";
import LearnHomeVideoIntro from "~/features/marketing/LearnHomeVideoIntro";
import { setClassWalletSession } from "~/utils/classWalletSession";
import "~/features/marketing/learn-more.css";

// Note: duplicated a fair bit in home-learn-more . Put into reusable components
// if in the end we use it long term.
function ClassWalletLearnMorePage() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("classWalletOrderId");
  const email = searchParams.get("email");
  useEffect(() => {
    if (orderId === null || email === null) {
      if (typeof window !== "undefined") {
        navigate("/get-started/homes-learn-more/");
      }
      return;
    }

    setClassWalletSession({
      orderId,
      email,
    });
  }, [orderId, email]);

  const getStartedTo = `/signup/`;

  return (
    <div className="Site-inner Site-inner--index">
      <div className="Content-outer">
        <LearnHomeVideoIntro
          buttonTo={getStartedTo}
          buttonLabel="ClassWallet customers start here"
        />
        <TwoColumnTextImage
          text="Prevent Summer Learning Loss and help your child acquire a solid foundational understanding in a subject critical for future academic success!"
          image={
            <StaticImage
              src="../../images/arithmenillustrations-06b.png"
              layout="fullWidth"
              alt="I don't remember this"
              placeholder="none"
            />
          }
        />
        <CostBenefits />
        <TitleBanner
          theme="red"
          headline2="New to ProblemScape?"
          buttons={[
            {
              to: getStartedTo,
              label: "ClassWallet customers start here",
            },
          ]}
        >
          Sign up for a Parent account so that you can create accounts for your
          students &amp; check on their progress!
        </TitleBanner>
        <WhatDoYouGet />
        <StandardTestimonials imageBackground />
        <TwoColumnTextImage
          text="Give your kid a leg up in their life-long math journey by harnessing the power of our engaging algebra adventure, designed to build strong mathematical foundations and boost confidence in tackling challenging concepts."
          image={
            <StaticImage
              src="../../images/paintball.png"
              layout="fullWidth"
              alt="Paintball game"
              placeholder="none"
            />
          }
        />
        <LegUpSection />
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="ClassWallet - Learn More" />;
}

export default ClassWalletLearnMorePage;
